import React from 'react';
import { Container, Row } from 'reactstrap';
import { graphql } from 'gatsby';
import { Helmet, useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { SecondaryButton } from "../../components/buttons";

const Success: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t('name')} - ${t('slogan')}`}</title>
      </Helmet>
      <section id={'success'}>
        <Container>
          <Row>
            <div className={'wrapper'}>
              <h1>{t('success.title')}</h1>
              <p>{t('success.description')}</p>
              <Link to={'/'}>
                <SecondaryButton>{t('success.back')}</SecondaryButton>
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Success;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
